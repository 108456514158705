import React from 'react';

import { styButtonWrapper } from './styles';

function ButtonMap() {
  return (
    <div className="row">
      <div className="col-md-12">
        <a href="https://goo.gl/maps/KquKCxnJfNpJJLNt8" target="_blank" rel="noreferrer">
          <div css={styButtonWrapper}>
            <div className="text_map">
            <span style={{ color: '#fff' }}>Buka Maps</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default React.memo(ButtonMap);
